.conditions-modal {
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  width: 90%;
  overflow-y: scroll;
  height: 80vh;
}
.conditions-container {
  padding: 1rem;
  display: grid;
  margin-bottom: 1rem;
}

.conditions-title {
  font-weight: 700;
  font-size: 1.3rem;
}

.conditions-content {
  white-space: pre-wrap;
  word-wrap: break-word;
}
