.wrapper-cancel-bookings {
  border-radius: 1rem;
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
